<template>
  <section class="section-profile">
    <h2 class="section-profile__title">
      Настройки профиля
    </h2>
    <Form
      v-slot="{ errors }"
      class="section-profile__form"
      @submit="checkForm"
    >
      <!-- Сообщение об ошибке если что то пошло не так -->
      <vAlertMessage v-if="USER_ERROR.length > 0">
        {{ USER_ERROR }}
      </vAlertMessage>

      <!-- Аватарка -->
      <div
        v-if="USER_DATA.role_user === 'waiter'"
        class="section-profile__photo-block"
      >
        <div class="section-profile__photo-wrapper">
          <div
            v-if="USER_DATA.waiter && USER_DATA.waiter.avatar_link"
            class="section-profile__photo-del"
            @click="delAvatar"
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 1L1 9M1 1L9 9L1 1Z"
                stroke="#EC4E4E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <img
            v-if="USER_DATA.waiter && USER_DATA.waiter.avatar_link"
            :src="'https://storage.tapper.cloud/prod/img/avatar/'+ USER_DATA.waiter.avatar_link"
            alt="avatar"
            class="section-profile__photo"
          >
        </div>
        <div class="section-profile__photo-edit">
          <input
            type="file"
            class="section-profile__file"
            @change="changeAvatar($event)"
          >
          {{ USER_DATA.waiter && USER_DATA.waiter.avatar_link ? 'Редактировать фото' : 'Загрузить фото' }}
        </div>
      </div>

      <fieldset class="section-profile__group">
        <!-- Название заведения -->
        <vInput
          id="name_shop"
          v-model="name_shop"
          placeholder="Название заведения"
          :required="true"
          rules="required"
          :error="SHOP_ERROR"
          :disabled="USER_DATA.role_user === 'waiter'"
        />

        <!-- Ваше имя -->
        <vInput
          v-if="USER_DATA.role_user === 'waiter'"
          id="name"
          v-model="name"
          placeholder="Ваше имя на кассе"
          :disabled="true"
        />

        <vInput
          v-if="USER_DATA.role_user === 'waiter'"
          id="display_name"
          v-model="display_name"
          placeholder="Имя"
          :disabled="false"
          :error="nameWaiterError"
        />

        <div
          v-if="USER_DATA.role_user === 'waiter'"
          class="section-profile__legend-info section-profile__legend-info--mobile"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 17V12H11M11.9119 8.05972C11.9659 8.10559 12 8.17383 12 8.25C12 8.29461 11.9883 8.3365 11.9678 8.37276M11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874M11.9119 8.05972L11.749 8.45312L11.5671 8.07874M11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276M11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276M11.5322 8.37276L11.75 8.25L11.9678 8.37276M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
              stroke="#EC4E4E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-if="accountAvailability">
            Для отправки отчёта, вы должны подключить вашу учетную запись
            Telegram. Для этого нажмите на кнопку “Привязать Telegram”, а затем
            нажмите на кнопку “Начать” или напишите команду /start в боте. На
            подключенную учетную запись Вам будут приходить отчеты.
          </span>
          <span v-if="!accountAvailability">
            Если вам необходимо отвязать учетную запись Telegram, то нажмите на
            кнопку “Отвязать Telegram“
          </span>
        </div>

        <vInput
          v-if="USER_DATA.role_user !== 'waiter'"
          id="name"
          v-model="name"
          placeholder="Ваше имя"
          :required="true"
          rules="required"
          :disabled="false"
        />

        <!-- Телефон -->
        <vInput
          v-if="USER_DATA.role_user !== 'waiter'"
          id="phone"
          v-model="phone"
          placeholder="Телефон"
          :required="true"
          rule="phone"
        />

        <div class="section-profile__inputs-list">
          <div
            v-if="messengerInputList.length"
            class="section-profile__inputs-list-wrap"
          >
            <legend
              v-if="USER_DATA.role_user === 'manager'"
              class="section-profile__legend-star-container"
            >
              <div class="section-profile__legend-star">
                <VIconStarBlue />
                <h3>— Управляющий</h3>
              </div>
              <p>
                Контакту управляющего помимо основных <br>
                сообщений, отправляется сообщение <br>
                о результатах за предыдущий день
              </p>
            </legend>
            <!------------ОТВЯЗКА И ПРИВЯЗКА ТЕЛЕГРАММА------------>
            <div
              v-if="!accountAvailability && USER_DATA.role_user === 'waiter'"
              class="section-profile__inputs-list-item"
            >
              <div class="section-profile__login">
                <div class="login-placeholder">
                  Логин Telegram
                </div>
                <div class="login-name">
                  {{ telegramLogin }}
                </div>
              </div>
            </div>
            <div
              v-if="USER_DATA.role_user === 'waiter'"
              class="section-profile__inputs-list-item"
            >
              <div
                class="section-profile__button"
                :class="{ red: !accountAvailability }"
                @click="bindingTelegram"
              >
                <p
                  class="section-profile__button-text"
                  :class="{
                    blue: accountAvailability,
                    red: !accountAvailability,
                  }"
                >
                  {{
                    accountAvailability
                      ? 'Привязать Telegram'
                      : 'Отвязать Telegram'
                  }}
                </p>
                <VIconTelegramLogo />
              </div>
            </div>
            <!------------ОТВЯЗКА И ПРИВЯЗКА ТЕЛЕГРАММА------------>
            <div v-if="USER_DATA.role_user !== 'waiter'">
              <div
                v-for="(input, index) in messengerInputList"
                :key="input.id"
                class="section-profile__inputs-list-item"
                :class="{ notFirst: index !== 0 }"
              >
                <div
                  class="section-profile__inputs-list-inner"
                  :class="{ err: input.error }"
                >
                  <vMessengerInput
                    :ref="input.placeholder"
                    v-model="input.name"
                    :empty-field-error="
                      emptyFieldError && USER_DATA.role_user == 'waiter'
                    "
                    :value="input.name"
                    :placeholder="
                      USER_DATA.role_user !== 'waiter'
                        ? `Логин Telegram №${index + 1}`
                        : 'Для отправки отчетов'
                    "
                    @toggleOutsideClick="toggleOutsideClick"
                  />

                  <div
                    v-if="USER_DATA.role_user === 'manager'"
                    class="section-profile__star"
                    @click="onMainer(index)"
                  >
                    <VIconStar :icon-blue="input.mainer" />
                    <div
                      v-if="!input.mainer"
                      class="section-profile__star-tooltip"
                    >
                      Сделать управляющим
                    </div>
                    <div
                      v-if="input.mainer"
                      class="section-profile__star-tooltip"
                    >
                      Является управляющим
                    </div>
                  </div>

                  <div
                    v-if="index !== 0"
                    class="vMessengerInput-close"
                    @click="removeTelegramField(input.id)"
                  />
                </div>
                <div
                  v-if="input.error"
                  class="vMessengerInput-error"
                >
                  {{ input.error }}
                </div>
              </div>
            </div>
          </div>
          <button
            v-if="
              messengerInputList.length < 16 &&
                USER_DATA.role_user === 'manager'
            "
            class="section-profile__add-btn"
            type="button"
            @click="addTelegramField"
          >
            Добавить +
          </button>
        </div>

        <legend class="section-profile__legend">
          <h3>Личные данные</h3>
          <p>Основная информация профиля</p>
          <div
            v-if="USER_DATA.role_user === 'waiter'"
            class="section-profile__legend-info"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 17V12H11M11.9119 8.05972C11.9659 8.10559 12 8.17383 12 8.25C12 8.29461 11.9883 8.3365 11.9678 8.37276M11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874M11.9119 8.05972L11.749 8.45312L11.5671 8.07874M11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276M11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276M11.5322 8.37276L11.75 8.25L11.9678 8.37276M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
                stroke="#EC4E4E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span v-if="accountAvailability">
              Для отправки отчёта, вы должны подключить вашу учетную запись
              Telegram. Для этого нажмите на кнопку “Привязать Telegram”, а
              затем нажмите на кнопку “Начать” или напишите команду /start в
              боте. На подключенную учетную запись Вам будут приходить отчеты.
            </span>
            <span v-if="!accountAvailability">
              Если вам необходимо отвязать учетную запись Telegram, то нажмите
              на кнопку “Отвязать Telegram“
            </span>
          </div>
        </legend>
      </fieldset>

      <fieldset class="section-profile__group">
        <!-- E-mail -->
        <vInput
          id="email"
          v-model="email"
          placeholder="E-mail"
          :required="true"
          rule="mail|max:254"
          :disabled="true"
        />

        <!-- Новый пароль -->
        <vInput
          id="password"
          v-model="password"
          type="password"
          placeholder="Новый пароль"
          :error="errorMessage"
        />

        <!-- Подтверждение нового пароля -->
        <vInput
          id="confirmation"
          v-model="confirmPassword"
          type="password"
          placeholder="Подтверждение нового пароля"
          rules="confirmed:@password"
        />
        <legend class="section-profile__legend">
          <h3>Персональная информация</h3>
          <p>Ваш основной E-mail и пароль</p>
        </legend>
      </fieldset>

      <fieldset class="section-profile__group">
        <!--        &lt;!&ndash; Дата обновления &ndash;&gt;-->
        <!--        <vInput-->
        <!--          id="updateDate"-->
        <!--          v-model="updateDate"-->
        <!--          :value="updateDate"-->
        <!--          placeholder="Дата обновления"-->
        <!--          :disabled="true"-->
        <!--        />-->

        <!--        &lt;!&ndash; Дата последней авторизации &ndash;&gt;-->
        <!--        <vInput-->
        <!--          id="authDate"-->
        <!--          v-model="authDate"-->
        <!--          :value="authDate"-->
        <!--          placeholder="Дата последней авторизации"-->
        <!--          :disabled="true"-->
        <!--        />-->

        <!--        <legend class="section-profile__legend">-->
        <!--          <h3>Последняя активность</h3>-->
        <!--          <p>История активности</p>-->
        <!--        </legend>-->
      </fieldset>

      <fieldset
        v-if="USER_DATA.tips_type === 'LINK'"
        class="section-profile__group"
      >
        <!-- ВТБ -->
        <vInput
          id="tips_link"
          v-model="tips_link"
          :error="errorTipsLink"
          placeholder="Ссылка на чаевые"
        />
        <legend class="section-profile__legend">
          <h3>Ссылка на чаевые</h3>
          <p>
            Здесь необходимо добавить ссылку на сайт, <br>
            где гость сможет оставить вам чаевые. <br>
            Гостям, после оплаты заказа будет отображаться кнопка <br>
            "Оставить чаевые" с введенной вами ссылкой.
          </p>
        </legend>
      </fieldset>

      <vButton
        v-if="
          USER_DATA.role_user === 'waiter' &&
            USER_DATA.card_linked == null &&
            USER_DATA.tips_type !== 'LINK'
        "
        type="button"
        :disabled="Object.keys(errors).length > 0 || errorMessage.length > 0"
        @click="cardVerification"
      >
        Привязать карту
      </vButton>

      <vButton
        v-if="
          USER_DATA.role_user === 'waiter' &&
            USER_DATA.tips_type !== 'LINK' &&
            USER_DATA.card_linked !== null &&
            USER_DATA.card_linked.number_card
        "
        type="button"
        :disabled="true"
        @click="cardVerification"
      >
        Карта привязана
        {{
          USER_DATA.card_linked !== null && USER_DATA.card_linked.number_card
        }}
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.5"
            cy="12"
            r="9.25"
            fill="white"
            stroke="#25C26E"
            stroke-width="1.5"
          />
          <path
            d="M16.0406 10.1001L12.0153 14.1079L9.6001 11.6927"
            stroke="#25C26E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </vButton>

      <div
        v-if="
          USER_DATA.role_user === 'waiter' &&
            USER_DATA.tips_type !== 'LINK' &&
            USER_DATA.card_linked !== null &&
            USER_DATA.card_linked.number_card
        "
        class="change-card"
        @click="cardVerification"
      >
        Изменить карту
      </div>

      <vButton
        type="submit"
        :fix-width="true"
        :disabled="disabledSaveBtn || Object.keys(errors).length > 0"
      >
        Сохранить
      </vButton>
    </Form>
    <div
      v-show="helpModal"
      class="modalInstruction"
    >
      <div
        class="modalInstruction__overlay"
        @click="helpModal = false"
      />
      <div class="modalInstruction__content">
        <div class="modalInstruction__title">
          <vCloseButton @click="helpModal = false" />
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              width="24"
              height="24"
              rx="12"
              fill="#419FD9"
            />
            <rect
              x="0.5"
              width="24"
              height="24"
              rx="12"
              fill="url(#paint0_linear_3564_87319)"
            />
            <path
              d="M5.89368 11.7355C9.38334 10.1831 11.7103 9.15973 12.8746 8.66527C16.199 7.2535 16.8897 7.00826 17.34 7.00008C17.439 6.99838 17.6604 7.02344 17.8038 7.14226C17.9249 7.2426 17.9583 7.37813 17.9742 7.47325C17.9901 7.56838 18.01 7.78508 17.9942 7.9544C17.8141 9.88701 17.0346 14.5769 16.638 16.7415C16.4702 17.6574 16.1398 17.9645 15.8199 17.9945C15.1248 18.0599 14.5969 17.5255 13.9236 17.0749C12.8701 16.3697 12.2749 15.9308 11.2522 15.2427C10.0703 14.4475 10.8365 14.0104 11.51 13.2962C11.6863 13.1092 14.7492 10.2648 14.8084 10.0067C14.8159 9.97446 14.8227 9.85417 14.7527 9.79065C14.6828 9.72713 14.5794 9.74885 14.5049 9.76612C14.3992 9.79061 12.7162 10.9264 9.45566 13.1736C8.97792 13.5086 8.54521 13.6718 8.1575 13.6632C7.73009 13.6538 6.90793 13.4165 6.29673 13.2136C5.54708 12.9648 4.95127 12.8333 5.00315 12.4107C5.03017 12.1906 5.32702 11.9656 5.89368 11.7355Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3564_87319"
                x1="12.5"
                y1="0"
                x2="12.5"
                y2="23.8219"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#2AABEE" />
                <stop
                  offset="1"
                  stop-color="#229ED9"
                />
              </linearGradient>
            </defs>
          </svg>
          Инструкция, где найти имя пользователя в телеграм и как открыть
          настройки конфиденциальности
        </div>
        <div class="modalInstruction__container">
          <div class="modalInstruction__step step1">
            <span>1. Первый шаг</span>
            Открываете (скачиваете) Telegram , если у вас нет Telegram
            зарегистрируйте его на ваш текущий номер телефона
          </div>
          <div class="modalInstruction__step step2">
            <span>2. Второй шаг</span>
            Открываете внутри приложения <br>
            “Настройки” > Информация <br>
            <ul>
              <li>
                Если у вас пустое поле “Имя пользователь” укажите его. На
                рисунке подсвечено красным
              </li>
              <li>
                Это ваше имя пользователя, которое нужно вписать в поле “Для
                отправки отчёта” в сервисе Таппер
              </li>
            </ul>
            <img src="@/assets/images/instruction1.jpg">
          </div>
          <div class="modalInstruction__step step3">
            <span>3. Третий шаг</span>
            Открываете внутри приложения <br>
            “Настройки” > Конфиденциальность <br>
            <ul>
              <li>
                Выставляете такие же параметры как на рисунке, которые
                подсвечены красным цветом
              </li>
              <li>Это нужно, чтобы наш бот смог вас авторизовать</li>
            </ul>
            <img src="@/assets/images/instruction2.jpg">
          </div>
        </div>
      </div>
    </div>
    <VModalUnlinkingAccount
      v-if="untyingModal"
      @untyingTelegram="untyingTelegram"
      @closeUntyingModal="closeUntyingModal"
    />

    <VModalPolitics
      v-if="modalPolitics"
      :legal-document="document"
      @confirmDocument="confirmDocument"
      @togglePoliticsModal="togglePoliticsModal"
      @logoutFunc="logoutFunc"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { URL_TELEGRAM } from '@/constants'
import convertDateFormat from '@/utils/convertDateFormat'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import convertPhoneToFull from '@/utils/convertPhoneToFull'
import { Form } from 'vee-validate'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vAlertMessage from '@/components/v-alert-message'
import vCloseButton from '@/components/v-close-button'
import VIconStarBlue from '@/assets/images/iconAdmin/v-icon-star-blue.vue'
import VIconStar from '@/assets/images/iconAdmin/v-icon-star.vue'
import VIconTelegramLogo from '@/assets/images/iconAdmin/v-icon-telegram-logo.vue'
import VModalUnlinkingAccount from '@/components/modals/v-modal-unlinking-account.vue'
import vMessengerInput from '@/components/v-messenger-input'
import VModalPolitics from '@/components/modals/v-modal-politics'
import logs from './logs'
export default {
  name: 'VSectionProfile',
  components: {
    Form,
    vInput,
    vButton,
    vAlertMessage,
    vCloseButton,
    VIconStarBlue,
    VIconStar,
    VIconTelegramLogo,
    VModalUnlinkingAccount,
    vMessengerInput,
    VModalPolitics
  },
  data() {
    return {
      addressMain: '',
      iconBlue: 0,
      outsideClick: false,
      name_shop: '',
      name: '',
      display_name: '',
      nameWaiterError: '',
      phone: '',
      email: '',
      messenger: '',
      password: '',
      confirmPassword: '',
      updateDate: '',
      authDate: '',
      errorMessage: '',
      errorFieldTelega: false,
      messengerInputList: [],
      helpModal: false,
      emptyFieldError: false,
      tips_link: '',
      errorTipsLink: '',
      accountAvailability: true,
      telegramLogin: null,
      untyingModal: false,
      modalPolitics: false,
      document: {},
      telegramFlag: false
    }
  },

  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED', 'USER_DATA', 'USER_ERROR']),
    ...mapGetters('shops', ['SHOP_ERROR']),
    disabledSaveBtn() {
      return this.emptyFieldError ||
        this.errorMessage.length > 0 ||
        this.nameWaiterError.length > 0 ||
        this.errorFieldTelega ||
        this.USER_DATA.tips_type === 'LINK'
        ? this.errorTipsLink.length > 0
        : false
    },
  },
  watch: {
    password() {
      if (this.password.length < 6 && this.password.length !== 0) {
        this.errorMessage = 'Введите минимум 6 символов'
      } else {
        this.errorMessage = ''
      }
    },
    tips_link() {
      const regexp = /^(http)+[s]?[:][\/\/]{2}[\w\d_-]*[\.]{1}[a-z]{2,5}/
      const res = regexp.test(this.tips_link)
      if (!res && this.tips_link.length > 0) {
        this.errorTipsLink = 'Введите корректную ссылку'
      } else {
        this.errorTipsLink = ''
      }
    },

    messengerInputList: {
      deep: true,
      handler(messengerInputList) {
        this.errorFieldTelega = false
        this.emptyFieldError = false
        messengerInputList.forEach((i, index) => {
          // if(!i.name.length) this.errorFieldTelega = true
          const regexp = /@/
          const res = regexp.test(i.name)
          if (res) {
            messengerInputList[index].error = 'Не корректное имя Telegram'
            this.errorFieldTelega = true
          } else {
            messengerInputList[index].error = ''
          }
        })
      },
    },
    display_name() {
      if (this.display_name.length) {
        const regexp = /(^[а-яА-ЯёЁ])[\s{0,}а-яА-ЯёЁ-]+$/
        const res = regexp.test(this.display_name)
        !res
          ? (this.nameWaiterError = 'Введено некорректное имя')
          : (this.nameWaiterError = '')
      } else {
        this.nameWaiterError = ''
      }
    },
  },
  async mounted() {
    await this.GET_USER_DATA(this.GET_AUTHENTIFICATED)
    await this.loadData()
    const vaasheFull = window.location
    const operationField = vaasheFull.toString().indexOf('operation')
    this.telegramLogin = this.USER_DATA.telegram[0]?.name

    if (this.telegramLogin) {
      this.accountAvailability = false
    }

    if (this.USER_DATA.card_linked && this.USER_DATA.card_linked.tips_link) {
      this.tips_link = this.USER_DATA.card_linked.tips_link
    }

    // Верификация карты
    if (
      this.$router.currentRoute.value.query?.MD &&
      this.$router.currentRoute.value.query?.PaRes
    ) {
      this.CHAANGE_ACTIVE_PROFILE_MENU({ name: 'Cloud', code: 'cloud' })
    }

    if(this.USER_DATA.role_user === 'waiter') {
      this.checkLegalDocuments()
        window.addEventListener('focus', () => {
          if (this.telegramFlag) {
            location.reload()
          }
        })
    }

    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('menu', ['CHAANGE_ACTIVE_PROFILE_MENU']),
    ...mapActions('user', ['GET_USER_DATA', 'UPDATE_USER_DATA', 'LOGOUT']),
    ...mapActions('shops', ['CLEAR_SHOP_ERRORS']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('telegram', [
      'CREATE_TELEGRAM',
      'UPDATE_TELEGRAM',
      'DELETE_WAITER',
    ]),
    ...mapActions('waiter', [
      'DEL_AVATAR',
      'UPLOAD_AVATAR',
      'CARD_VERIFICATION',
      'UPDATE_TIPS_LINK',
    ]),
    ...mapActions('authentication', ['CHAANGE_STATUS']),
    async cardVerification() {
      this.TOGGLE_PRELOADER(true)
      await this.CARD_VERIFICATION(this.GET_AUTHENTIFICATED)
    },
    ...mapActions('legalDocuments', [
      'FETCH_NOT_FAMILIRIZATION_DOCUMENTS',
      'CONFIRM_NOT_FAMILIRIZATION_DOCUMENTS',
    ]),
    addTelegramField() {
      if (this.messengerInputList.length) {
        this.messengerInputList.push({
          id:
            this.messengerInputList[this.messengerInputList.length - 1].id + 1,
          placeholder: `messanger${
            this.messengerInputList[this.messengerInputList.length - 1].id + 1
          }`,
          name: '',
          error: '',
        })
      } else {
        this.messengerInputList.push({
          id: 0,
          placeholder: 'messanger2',
          name: '',
          error: '',
        })
      }
    },
    removeTelegramField(id) {
      const newArrayInputs = this.messengerInputList
        .filter((i) => i.id !== id)
        .map((i, index) => {
          return {
            id: index,
            placeholder: 'messanger' + (index + 1),
            name: i.name,
            mainer: i.mainer,
            error: '',
          }
        })
      this.messengerInputList = newArrayInputs
    },
    changeAvatar(event) {
      this.TOGGLE_PRELOADER(true)
      let self = this
      const file = event.target.files[0]
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async function () {
        await self.UPLOAD_AVATAR({
          img: reader.result,
          token: self.GET_AUTHENTIFICATED,
        })
        await self.GET_USER_DATA(self.GET_AUTHENTIFICATED)
        setTimeout(() => {
          self.TOGGLE_PRELOADER(false)
        }, 500)
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    toggleOutsideClick(val) {
      this.outsideClick = val
    },
    loadData() {
      this.name_shop = this.USER_DATA.shop_linked
        ? this.USER_DATA.shop_linked.name
        : this.USER_DATA.shops_list[0]?.name
      this.name = this.USER_DATA.name
      // this.name_shop = this.USER_DATA.shops_list[0]?.name
      this.phone =
        this.USER_DATA.phone !== null
          ? convertPhoneToFull(this.USER_DATA.phone.slice(1))
          : ''
      this.email = this.USER_DATA.email
      this.updateDate = convertDateFormat(this.USER_DATA.updated_at)
      this.authDate = convertDateFormat(this.USER_DATA.created_at)
      // this.messenger = this.USER_DATA.telegram && this.USER_DATA.telegram !== null ? this.USER_DATA.telegram.name.slice(1) : ''
      const telega = this.USER_DATA.telegram.map((i) => {
        if (i.mainer) {
          this.addressMain = i.name
        }
        return { ...i, name: i.name.replace(/^@/, ''), error: '' }
      })

      this.messengerInputList = !telega.length
        ? [{ id: 0, placeholder: 'messenger(1)', name: '', error: '' }]
        : [...telega]

      this.display_name = this.USER_DATA.display_name
        ? this.USER_DATA.display_name
        : ''
    },
    async delAvatar() {
      this.TOGGLE_PRELOADER(true)
      await this.DEL_AVATAR(this.GET_AUTHENTIFICATED)
      await this.GET_USER_DATA(this.GET_AUTHENTIFICATED)
      await this.loadData()
      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
    },
    async checkForm() {
      this.TOGGLE_PRELOADER(true)
      this.CLEAR_SHOP_ERRORS()
      const userData = {
        name: this.name,
        password: this.password,
        phone:
          this.USER_DATA.role_user !== 'waiter'
            ? convertPhoneFormat(this.phone)
            : '',
        name_shop: this.name_shop,
        display_name: this.display_name,
      }
      const reqTelegram = { names: [], mainer_name: '' }
      this.messengerInputList = this.messengerInputList.filter(
        (e) => e.name.length >= 5
      )
      this.messengerInputList.forEach((i) => {
        reqTelegram.names.push('@' + i.name)
      })

      if (this.addressMain === '') {
        reqTelegram.mainer_name = ''
      } else {
        if (this.addressMain.includes('@')) {
          reqTelegram.mainer_name = this.addressMain
        } else {
          reqTelegram.mainer_name = '@' + this.addressMain
        }
      }

      if (!reqTelegram.names.includes(reqTelegram.mainer_name)) {
        reqTelegram.mainer_name = ''
      }

      if (!reqTelegram.names.length) {
        this.messengerInputList = [
          { id: 0, placeholder: 'messenger(1)', name: '', error: '' },
        ]
        setTimeout(() => {
          this.emptyFieldError = true
          this.TOGGLE_PRELOADER(false)
        }, 300)
      }

      if (
        this.name_shop === this.USER_DATA.shops_list[0]?.name ||
        (this.USER_DATA.shop_linked &&
          this.name_shop === this.USER_DATA.shop_linked.name)
      ) {
        await this.UPDATE_USER_DATA({
          userData,
          token: this.GET_AUTHENTIFICATED,
        })

        if (
          !this.USER_DATA.telegram.length ||
          this.USER_DATA.telegram == null
        ) {
          await this.CREATE_TELEGRAM({
            messenger: reqTelegram,
            token: this.GET_AUTHENTIFICATED,
          })
        } else {
          await this.UPDATE_TELEGRAM({
            messenger: reqTelegram,
            token: this.GET_AUTHENTIFICATED,
          })
        }
      } else {
        if (this.SHOP_ERROR.length < 1) {
          const userData = {
            name: this.name,
            password: this.password,
            phone: convertPhoneFormat(this.phone),
            name_shop: this.name_shop,
            display_name: this.display_name,
          }
          await this.UPDATE_USER_DATA({
            userData,
            token: this.GET_AUTHENTIFICATED,
          })
          if (
            !this.USER_DATA.telegram.length ||
            this.USER_DATA.telegram == null
          ) {
            await this.CREATE_TELEGRAM({
              messenger: reqTelegram,
              token: this.GET_AUTHENTIFICATED,
            })
          } else {
            await this.UPDATE_TELEGRAM({
              messenger: reqTelegram,
              token: this.GET_AUTHENTIFICATED,
            })
          }
        }
      }

      if (this.USER_DATA.role_user === 'waiter') {
        const updateTipsRes = await this.UPDATE_TIPS_LINK({
          token: this.GET_AUTHENTIFICATED,
          tips_link: this.tips_link,
        })
        if (!updateTipsRes) {
          this.errorTipsLink = 'Неверная ссылка'
        }
      }

      this.CHAANGE_STATUS({ status: true, msg: 'Данные успешно изменены' })

      setTimeout(() => {
        this.TOGGLE_PRELOADER(false)
      }, 500)
      setTimeout(() => {
        this.CHAANGE_STATUS({
          status: false,
          msg: '',
        })
      }, 1500)
    },
    onMainer(index) {
      if (this.messengerInputList[index].mainer) {
        this.messengerInputList[index].mainer = 0
        this.addressMain = ''
      } else {
        this.messengerInputList.forEach((i, index) => {
          i.mainer = 0
        })
        this.messengerInputList[index].mainer = 1
        this.addressMain = this.messengerInputList[index].name
      }
    },

    bindingTelegram() {
      const id = this.USER_DATA.id
      if (this.accountAvailability) {
        this.telegramFlag = true
        window.open(
          `https://t.me/${URL_TELEGRAM}?start=waiter-subscribe-${id}`,
          '_blank'
        )
      } else {
        this.untyingModal = true
      }
    },

    async untyingTelegram() {
      this.TOGGLE_PRELOADER(true)
      await this.DELETE_WAITER({
        id: this.USER_DATA.telegram[0].id,
        token: this.GET_AUTHENTIFICATED,
      })
      setTimeout(() => {
        location.reload()
      }, 500)
    },

    closeUntyingModal() {
      this.untyingModal = false
    },

    openModal() {
      this.togglePoliticsModal()
      this.TOGGLE_PRELOADER(false)
    },

    async checkLegalDocuments() {
      const res = await this.FETCH_NOT_FAMILIRIZATION_DOCUMENTS(
        this.GET_AUTHENTIFICATED
      )
      if (res.length) {
        this.document = res[0]
        this.openModal()
      } else {
        this.TOGGLE_PRELOADER(false)
      }
    },

    async confirmDocument(code) {
      this.TOGGLE_PRELOADER(true)
      this.togglePoliticsModal()
      const payload = {
        token: this.GET_AUTHENTIFICATED,
        code: code,
      }
      const res = await this.CONFIRM_NOT_FAMILIRIZATION_DOCUMENTS(payload)
      if (res?.success) {
        this.checkLegalDocuments()
      } else {
        this.togglePoliticsModal()
        this.logoutFunc()
      }
    },

    togglePoliticsModal() {
      this.modalPolitics = !this.modalPolitics
    },

    async logoutFunc() {
      this.TOGGLE_PRELOADER(true)
      await this.LOGOUT()
      this.$router.push('/users')
    },
  },
}
</script>

<style lang="scss">
.section-profile {
  &__star-tooltip {
    position: absolute;
    right: -12px;
    top: calc(100% + 10px);
    background: #fff;
    border-radius: 10px;
    font-size: 12px;
    width: 156px;
    text-align: left;
    padding: 8px;
    line-height: 16px;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 4;
    color: #80869a;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 6px 24px rgba(0, 0, 0, 0.16);
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 30px;
      height: 13px;
      right: 10px;
      background: #fff;
      top: 0;
    }
    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 13px;
      background: #fff;
      top: -6px;
      right: 15px;
      transform: rotate(45deg);
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 32px rgba(0, 0, 0, 0.16);
      z-index: -1;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  &__star {
    cursor: pointer;
    position: relative;
    width: 18px;
    height: 19px;
    min-width: 18px;
    background-size: contain;
    right: 16.5%;
    @media (max-width: 770px) {
      right: 99px;
    }
    &:hover .section-profile__star-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
  &__add-btn {
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
    height: 30px;
    background: #ffffff;
    border: 1px solid #d5dbf1;
    border-radius: 6px;
    color: #413dff;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #f8f9fe;
    }
  }
  &__photo-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-bottom: 1px solid #edeef1;
    margin-bottom: 16px;
  }
  &__photo-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #cccfd7;
    margin-bottom: 16px;
    position: relative;
  }
  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  &__photo-edit {
    color: #80869a;
    font-size: 16px;
    cursor: pointer;
    position: relative;
  }
  &__file {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &__inputs-list-wrap {
    position: relative;
  }
  &__inputs-list-item {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  &__inputs-list-inner {
    display: flex;
    align-items: center;
    width: 100%;
    &.err .vMessengerInput {
      border-color: #ec4e4e;
      background: #f8e0e0;
    }
  }
  &__photo-del {
    width: 22px;
    height: 22px;
    background: #f7f8fa;
    border: 1px solid #edeef1;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    right: 0;
    top: 0;
  }
  &__legend-info {
    border: 1px solid #6764ff;
    padding: 16px 12px;
    border-radius: 11px;
    background: #fff;
    width: 343px;
    display: flex;
    font-size: 12px;
    line-height: 16px;
    font-family: 'PTRootUI';
    margin-top: 24px;
    text-align: left;
    font-weight: 500;
    @media (max-width: 1140px) {
      width: 243px;
    }
    &--mobile {
      display: none;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
      max-width: none;
      @media (max-width: 1023px) {
        display: flex;
      }
    }
    svg {
      min-width: 24px;
      min-height: 24px;
      margin-right: 10px;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__legend-star-container {
    position: absolute;
    top: 0;
    left: 110%;
    width: max-content;
    @media (max-width: 1023px) {
      position: relative;
      left: 0;
      margin-bottom: 16px;
      top: -4px;
    }
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #4e5672;
      text-align: left;
    }
  }
  &__legend-star {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    @media (max-width: 1023px) {
      margin-bottom: 8px;
    }
    h3 {
      margin: 0 0 0 15px;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #010d35;
      text-align: left;
      @media (max-width: 1023px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  &__login {
    position: relative;
    border: 1px solid #d5dbf1;
    border-radius: 12px;
    width: 100%;
    height: 54px;
    .login-placeholder {
      position: absolute;
      top: 8px;
      left: 12px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #80869a;
    }
    .login-name {
      position: absolute;
      bottom: 8px;
      left: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #010d35;
    }
  }
  &__button {
    cursor: pointer;
    border: 1px solid #d5dbf1;
    border-radius: 12px;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.red {
      background: #fff6f6;
      border: 1px solid #ec4e4e;
    }
    &-text {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      margin-right: 10px;
      &.blue {
        color: #413dff;
      }
      &.red {
        color: #ec4e4e;
      }
    }
  }
}
.change-card {
  color: #80869a;
  font-size: 16px;
  margin-bottom: 24px;
  cursor: pointer;
}
.vMessengerInput-close {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  margin-left: 8px;
  border: 1px solid #edeef1;
  border-radius: 50px;
  background: #f7f8fa;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 2px;
    height: 10px;
    background: #ec4e4e;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 2px;
    height: 10px;
    background: #ec4e4e;
  }
}
.vMessengerInput-error {
  padding-left: 12px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ec4e4e;
  width: 100%;
  text-align: left;
}
.modalInstruction {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    background: rgba(220, 222, 227, 0.9);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  &__content {
    border-radius: 11px;
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 24px 16px;
    width: 100%;
    max-width: 718px;
    @media (max-width: 900px) {
      max-width: 368px;
      height: 100vh;
      overflow: auto;
    }
  }
  &__row {
    display: flex;
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    margin-bottom: 14px;
  }
  &__step {
    border: 1px solid #d5dbf1;
    border-radius: 8px;
    padding: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    span {
      color: #6764ff;
      font-weight: 600;
      font-size: 13px;
      display: block;
      margin-bottom: 16px;
    }
    img {
      width: 100%;
    }
    li {
      padding-left: 12px;
      position: relative;
      min-height: 32px;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #000;
      }
    }
    &.step2 {
      grid-area: step2;
    }
    &.step1 {
      grid-area: step1;
    }
    &.step3 {
      grid-area: step3;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr 1fr;
    gap: 16px 16px;
    grid-template-areas:
      'step1 step1'
      'step2 step3'
      'step2 step3';
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 16px;
      grid-template-areas:
        'step1 step1'
        'step2 step2'
        'step3 step3';
    }
  }
}
</style>
