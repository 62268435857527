<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.235L4.179 18L5.388 11.88L1 7.392L7.179 6.621L10 1L12.821 6.621L19 7.392L14.612 11.88L15.821 18L10 15.235Z"
      fill="#413DFF"
      stroke="#413DFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VIconStarBlue',
}
</script>

<style lang="scss"></style>
