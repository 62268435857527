<template>
  <div class="vAlertMessage">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VAlertMessage',
}
</script>
<style lang="scss">
.vAlertMessage {
  border-radius: 12px;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ec4e4e;
  background: #f8e0e0;
  margin-bottom: 20px;
}
</style>
